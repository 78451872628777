import SigninStudent from "../components/SignStudent";
import SigninTeacher from "../components/Signin";
import Note from "../components/Note";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";
import { CURRENT_USER_QUERY } from "../components/User";
import redirect from "../lib/redirect";
import styled from "styled-components";
import { useRouter } from "next/router";
import { useQuery } from "react-apollo";

const UserForms = (props) => {
  if (!props.userType) {
    return (
      <Row>
        <Link href={{ pathname: "/", query: { user: "student" } }}>
          <a>
            <Button
              colour={"#A10D78"}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              Student Sign In
            </Button>
          </a>
        </Link>

        <Link href={{ pathname: "/", query: { user: "teacher" } }}>
          <a style={{ textDecoration: "underline", marginTop: "30px" }}>
            <Button
              colour={"#ABAE3D"}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              Teacher Sign In
            </Button>
          </a>
        </Link>
      </Row>
    );
  } else {
    return (
      <Column>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: -500 }}
            transition={{ ease: "easeIn", duration: 0.25 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
          >
            {props.userType === "student" ? (
              <SigninStudent />
            ) : (
              <SigninTeacher />
            )}
          </motion.div>
        </AnimatePresence>

        <Link
          href={{
            pathname: "/",
            query: {
              user: props.userType === "student" ? "teacher" : "student",
            },
          }}
        >
          <a
            style={{
              textDecoration: "underline",
              marginTop: "30px",
            }}
          >
            <SmallButton
              initial={{ opacity: 0.75 }}
              whileHover={{
                opacity: 1,
                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9, opacity: 1 }}
            >
              Sign in as a{" "}
              {props.userType === "student" ? "teacher" : "student"}{" "}
            </SmallButton>
          </a>
        </Link>
      </Column>
    );
  }
};

const SigninLayout = (props) => {
  const router = useRouter();
  const { user } = router.query;

  const { loading, error, data } = useQuery(CURRENT_USER_QUERY);

  if (loading) {
    return (
      <Full>
        <Welcome>
          <h1 style={{ color: "white", marginTop: "6vh" }}>MathsBURST</h1>

          <UserForms userType={null} />
        </Welcome>

        <Note />
      </Full>
    );
  }

  if (data.me) {
    return (
      <Full>
        <Welcome>
          <h1 style={{ color: "white", marginTop: "6vh" }}>MathsBURST</h1>

          <Center
            initial={{ opacity: 0, y: -100 }}
            transition={{ ease: "easeIn", duration: 0.25 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
          >
            <h2>Welcome {data.me.firstname} – Loading your class... </h2>
          </Center>
        </Welcome>
      </Full>
    );
  }

  return (
    <Full>
      <Welcome>
        <h1 style={{ color: "white", marginTop: "6vh" }}>MathsBURST</h1>

        <UserForms userType={user} />

        <ActionsRow>
          <a
            href={"mailto:support@mathsburst.com"}
            style={{
              color: "black",
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "5px 10px 5px 10px",
            }}
          >
            Contact Us
          </a>

          <a
            href={"https://forms.gle/qSLRbPEZhshTcXDC6"}
            style={{
              color: "black",
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "5px 10px 5px 10px",
            }}
          >
            Register for 2022
          </a>

          <a
            href={"https://about.mathsburst.com"}
            style={{
              color: "black",
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "5px 10px 5px 10px",
            }}
          >
            About MathsBURST
          </a>
        </ActionsRow>
      </Welcome>

      <Note />
    </Full>
  );
};

export default SigninLayout;

SigninLayout.getInitialProps = async (ctx) => {
  const { data } = await ctx.apolloClient.query({ query: CURRENT_USER_QUERY });

  if (data.me && data.me.teacher) {
    redirect(ctx, "/challenges/progress");
  }

  if (data.me && data.me.student) {
    redirect(ctx, "/levels");
  }

  return {};
};

const Button = styled(motion.button)`
  color: white;
  font-weight: bold;
  background-color: ${(props) => props.colour};
  border-radius: 20px;
  width: 350px;
  min-height: 80px;
  font-size: 28px;
  margin-right: 20px;
`;

const SmallButton = styled(motion.button)`
  background-color: ${(props) => props.colour};
  border-radius: 20px;
  width: 180px;
  min-height: 40px;
  font-size: 16px;
  margin: auto;
  margin-top: 20px;
  float: right;
`;

const Column = styled.div`
  margin: auto;
  margin-top: 7vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  width: 90vw;
  justify-content: center;
  align-items: baseline;
`;

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  width: 600px;
  justify-content: space-between;
  align-items: baseline;
`;

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Full = styled(motion.div)`
  height: 100vh;
  background-image: url("/home-background.jpg");
  background-size: cover;
`;

const Center = styled(motion.div)`
  background: white;
  width: 50vw;
  margin: auto;
  text-align: center;
  margin-top: 30vh;
`;
