import react from "react";
import styled from "styled-components";

const BrowserNote = (props) => (
  <Note>
    <h3 style={{ marginBottom: 0 }}>
      Please note that MathsBURST does not support Internet Explorer.{" "}
    </h3>
    <span>
      We recommend upgrading to the latest{" "}
      <a href="https://www.google.com.au/chrome">Google Chrome</a>,{" "}
      <a href="https://www.apple.com/au/safari/">Safari </a> or{" "}
      <a href="https://www.microsoft.com/en-au/windows/microsoft-edge">
        Microsoft Edge
      </a>
      .
    </span>
  </Note>
);

const Note = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0vw 10vw 2vh 10vw;
  background-color: #fffac1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span > a {
    text-decoration: underline;
  }
`;

export default BrowserNote;
