import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Form from "./styles/Form";
import Error from "./ErrorMessage";
import { CURRENT_USER_QUERY } from "./User";
import Router from "next/router";
import styled from "styled-components";
import redirect from "../lib/redirect";

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $passcode: String!) {
    signInStudent(email: $email, passcode: $passcode) {
      id
      passcode
      student {
        id
        class {
          id
          grade
        }
        school {
          id
          name
        }
      }
    }
  }
`;

class Signin extends Component {
  state = {
    passcode: "",
    email: "",
  };
  saveToState = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <Column>
        <Mutation
          mutation={SIGNIN_MUTATION}
          variables={this.state}
          refetchQueries={[{ query: CURRENT_USER_QUERY }]}
          onCompleted={(data) => {
            redirect({}, `/levels`);
          }}
        >
          {(signInStudent, { data, error, loading, called }) => (
            <Form
              id="signin"
              method="post"
              onSubmit={async (e) => {
                e.preventDefault();
                await signInStudent();
                this.setState({ email: "", passcode: "" });
              }}
            >
              <fieldset disabled={loading} aria-busy={loading}>
                <h2>Sign into your student account</h2>
                <Error error={error} />
                <label htmlFor="email">
                  Email
                  <input
                    type="email"
                    name="email"
                    list="data"
                    placeholder="email"
                    value={this.state.email}
                    onChange={this.saveToState}
                  />
                  <datalist id="data">
                    {[
                      "@education.nsw.gov.au",
                      "@cgstudents.catholic.edu.au",
                    ].map((item, key) => (
                      <option key={key} value={this.state.email + item} />
                    ))}
                  </datalist>
                </label>
                <label htmlFor="passcode">
                  Passcode
                  <input
                    type="password"
                    autoComplete="new-password"
                    name="passcode"
                    placeholder="passcode"
                    value={this.state.passcode}
                    onChange={this.saveToState}
                  />
                </label>

                <button type="submit">Sign In</button>
              </fieldset>
            </Form>
          )}
        </Mutation>
      </Column>
    );
  }
}

export default Signin;

const Column = styled.div`
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 60vw;
  background-color: white;
  border-radius: 20px;
`;
